<template>
  <div class='sub-page' >
    <div class='sub-tab-wrap'>
      <DxTabPanel
          id="tabpanel"
          :selected-index.sync="selectedIndex"
          :data-source='tabs'
          :focusStateEnabled='true'
        >
        <template #title="{ data: tab }">
          <div class='sub-title-wrap-tab'>
            <ul>
              <li class='float-left sub-title'>{{tab.title}}</li>
            </ul>
          </div>
        </template>
      </DxTabPanel>
    </div>
   <div class='sub-content'> <!-- 여기까지 page header 이하 수정해서 사용-->
     <div class='goods-grid'>
      <dx-data-grid ref='refRawMaterialGrid'
        :data-source='rawMaterialInfoList'
        :show-borders = 'true' :showRowLines = 'false' :showColumnLines='true'
        :hover-state-enabled = "true" :allowColumnResizing = 'true'
        column-resizing-mode = 'widget' :allow-column-reordering = "true"
        :height='gridHeight' :row-alternation-enabled="true"
        :focused-row-enabled="true"
        :auto-navigate-to-focused-row="false"
        key-expr="품번"
        @cell-prepared='onCellPrepared'
        @toolbar-preparing='onToolbarPreparing'
      >
        <DxSearchPanel
          :visible="true"
          placeholder="검색..."
        />
        <DxExport
          :enabled="true"
          :file-name="stockTitle"
        />
        <DxFilterRow
          :visible="true"
          apply-filter="auto"
        />
        <DxColumnFixing :enabled="true"/>
        <DxColumn v-if="$store.getters.getGroupName !== null"
          :allowEditing='false'
          :caption="$store.getters.getGroupName"
          data-field="차종"
          sort-order='asc'
        >
        </DxColumn>
        <DxColumn
          :allowEditing='false'
          caption="품번"
          data-field="품번"
          sort-order='asc'
        >
        </DxColumn>
        <DxColumn
          :allowEditing='false'
          caption="품명"
          data-field="품명"
          :visible='true'
        >
        </DxColumn>
        <DxColumn
          :allowEditing='false'
          caption="자재유형"
          data-field="자재유형"
          :visible='true'
        >
          <DxLookup
            :data-source="meterialTypeLookup"
            display-expr="코드명"
            value-expr="코드"
          />
        </DxColumn>
        <DxColumn
          :allowEditing='false'
          caption="단위"
          data-field="단위"
          :visible='true'
        >
          <DxLookup
            :data-source="unitLookup"
            display-expr="코드명"
            value-expr="코드"
          />
        </DxColumn>
        <DxColumn
          caption="재고현황"
          alignment='center'
        >
          <DxColumn
            :allowEditing='false'
            caption="창고 현재고"
            data-field="창고재고"
            :visible='true'
            format='#,##0'
            dataType="number"
          />
          <DxColumn
            :allowEditing='false'
            caption="재공 현재고"
            data-field="현재공"
            :visible='true'
            format='#,##0'
            dataType="number"
          />
          <DxColumn
            :allowEditing='false'
            caption="합계"
            :calculate-cell-value="calculateStcokAmount"
            :visible='true'
            format='#,##0'
            dataType="number"
          />
        </DxColumn>
        <DxColumn
          :allowEditing='false'
          caption="창고이월재고"
          data-field="이월창고재고"
          :visible='true'
          format='#,##0'
          dataType="number"
        />
        <DxColumn
          :allowEditing='false'
          caption="창고재고보정"
          data-field="창고재고보정"
          :visible='false'
          format='#,##0'
          dataType="number"
        />
        <DxColumn
          :allowEditing='false'
          caption="이월재공"
          data-field="이월재공"
          :visible='true'
          format='#,##0'
          dataType="number"
        />
        <DxColumn
          :allowEditing='false'
          caption="재공보정"
          data-field="재공재고보정"
          :visible='false'
          format='#,##0'
          dataType="number"
        />
        <DxColumn
          :allowEditing='false'
          caption="입고(2개월)"
          data-field="입고수량"
          :visible='true'
          format='#,##0'
          dataType="number"
        />
        <DxColumn
          :allowEditing='false'
          caption="자재반품(2개월)"
          data-field="반품수량"
          :visible='true'
          format='#,##0'
          dataType="number"
        />
        <DxColumn
          :allowEditing='false'
          caption="금월자재출하"
          data-field="출하수량"
          :visible='false'
          format='#,##0'
        />
        <DxSummary>
          <DxTotalItem
            value-format='#,##0'
            column="차종"
            display-format="{0} (건)"
          />
        </DxSummary>
        <dx-paging :enabled="false"/>
        <dx-scrolling mode="virtual" showScrollbar='always' :useNative='false'/>
        <dx-sorting mode="multiple"/>
      </dx-data-grid>
    </div>
    <loading :active.sync="isLoading" color='rgb(2, 110, 156)' loader='bars'></loading>
  </div> <!--sub-conten end-->
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import AppLib from '../../../../MesSmartVue/src/share/AppLib'
import ConstDef from '../../../../MesSmartVue/src/share/ConstDef'
import DxTabPanel from 'devextreme-vue/tab-panel'
import DxDataGrid, {
  DxScrolling, DxFilterRow, DxExport, DxColumnFixing, DxTotalItem,
  DxSummary, DxColumn, DxPaging, DxSorting, DxSearchPanel, DxLookup
} from 'devextreme-vue/data-grid'

export default {
  name: 'Storage',
  data: function () {
    return {
      selectedIndex: 0,
      tabs: [
        {
          title: '재고현황'
        }
      ],
      isLoading: false,
      vendorInfo: null,
      gridHeight: null,
      rawMaterialInfoList: null,
      stockTitle: null,
      meterialTypeLookup: null,
      unitLookup: null
    }
  },
  components: {
    Loading,
    DxTabPanel,
    DxDataGrid,
    DxScrolling,
    DxFilterRow,
    DxExport,
    DxColumnFixing,
    DxTotalItem,
    DxSummary,
    DxColumn,
    DxPaging,
    DxSorting,
    DxSearchPanel,
    DxLookup
  },
  created () {
    document.title = ConstDef.TAB_TITLE
    this.vendorInfo = this.$store.getters.getVendorInfo
    console.log(this.vendorInfo)
    if (this.vendorInfo === undefined || this.vendorInfo === null) {
      this.$router.push('/')
    }
    this.isLoading = true
    setTimeout(() => {
      this.meterialTypeLookup = this.$store.getters.getBaseDataByType(ConstDef.자재유형)
      console.log(this.meterialTypeLookup)
      this.unitLookup = this.$store.getters.getBaseDataByType(ConstDef.단위)
      console.log(this.unitLookup)
      this.refreshCurrentStock()
    }, 1000)
  },
  mounted () {
    this.gridHeight = (screen.height - 250) * 0.95
  },
  updated () {
  },
  beforeDestroy: function () {

  },
  methods: {
    calcBadReturn (row) {
      return row.불량반품수량 + row.반송반품수량
    },
    calculateStcokAmount (row) {
      return row.창고재고 + row.현재공
    },
    onCellPrepared (e) {
      if (e.rowType === 'data' && e.column.caption === '합계') { // 창고재고
        this.$Q(e.cellElement).css('color', 'blue')
      }
    },
    onToolbarPreparing (e) {
      const baseDate = `${AppLib.calcMonthFromToday(-1).substring(0, 7)}-01`
      e.toolbarOptions.items.unshift(
        {
          location: 'before',
          template: function () {
            return `<div class="toolbar-label" style='margin-left:4px;color:blue;'><b>이월기준일: ${baseDate}</b></div>`
          }
        }
      )
    },
    refreshCurrentStock () {
      this.stockTitle = `${AppLib.getToday()}_구매품_재고`
      console.log(this.vendorInfo.거래처코드)
      const param = {
        toDate: AppLib.calcDateFromToday(1),
        품목유형: ConstDef.구매품,
        거래처코드: this.vendorInfo.거래처코드
      }
      this.isLoading = true
      this.$_sp.runNoEncodeFindProc('spFindAllB2bStockReceiptByToDateAndPtypeV2', param)
        .then((data) => {
          this.isLoading = false
          this.rawMaterialInfoList = this.$_sp.MakeModel(data)
          console.log(this.rawMaterialInfoList)
        })
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    }
  }
}
</script>

<style lang="scss">

</style>
